import { useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";

const Protected = ({ protect, children }) => {
  const navigate = useNavigate();
  let userToken = JSON.parse(localStorage.getItem("user"));

  useEffect(() => {
    if (userToken) {
      const timer = setTimeout(() => {
        navigate("/logout");
      }, 1800000); // 1800000 milliseconds = 30 minute

      // const timer = setTimeout(() => {
      //   navigate("/logout");
      // }, 10000); // 1800000 milliseconds = 30 minute

      return () => clearTimeout(timer);
    }
  }, [userToken, navigate]);

  if (protect) {
    if (!userToken) {
      return <Navigate to="/login" replace />;
    }
  } else if (userToken) {
    return <Navigate to="/" replace />;
  }

  return children;
};

export default Protected;
