import React, { useEffect, useState } from "react";
import Sidebar from "./Sidebar";
import Navbar from "./Navbar";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment-timezone";
import { useNavigate } from "react-router-dom";

//this method use for time formating
const formatDate = (dateString) => {
  if (!dateString) return "N/A";

  return moment.utc(dateString).format("YYYY-MM-DD hh:mm:ss A");
};

const APK = () => {
  const navigate = useNavigate();
  // const userDetails = JSON.parse(localStorage.getItem("user"));
  const [apkData, setAPKData] = useState("");

  useEffect(() => {
    const userDetails = JSON.parse(localStorage.getItem("user"));
    if (!userDetails || !userDetails.token) {
      navigate("/logout");
    }

    // Function to fetch APK data
    const fetchAPKData = () => {
      axios
        .get(`${process.env.REACT_APP_BASE_URL}get-apks`, {
          headers: {
            Authorization: `Bearer ${userDetails.token}`,
          },
        })
        .then((response) => {
          setAPKData(response.data.result[0]); // Set the data received from the API
        })
        .catch((error) => {
          console.error("Something went wrong.");
        });
    };

    fetchAPKData();
  }, []);

  const downloadAPK = () => {
    if (apkData !== undefined) {
      // Ensure you set the base URL correctly here
      // const baseUrl = process.env.REACT_APP_BASE_URL;
      const baseUrl = process.env.REACT_APP_BASE_URL_IMG; //this for stage env
      const apkUrl = `${baseUrl}${apkData.apk}`;

      const link = document.createElement("a");
      link.href = apkUrl;
      link.setAttribute("download", apkData.apk); // Suggested filename
      document.body.appendChild(link);
      link.click(); // Trigger the download
      link.remove(); // Clean up the DOM

      toast.success("APK Successfully Downloaded.");
    } else {
      toast.error("APK File Not Available.");
    }
  };

  return (
    <>
      <ToastContainer />
      <div id="wrapper">
        <Sidebar />
        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            <Navbar />
            <div className="container-fluid">
              <div className="card shadow mb-4">
                <div className="card-header py-3 d-flex justify-content-between">
                  <h6 className="m-0 font-weight-bold text-primary">
                    Latest APK Download
                  </h6>
                </div>
                <div className="card-body">
                  <div className="apk-download">
                    <div className="d-flex flex-column flex-md-row justify-content-center align-items-center gap-3 images">
                      <div className="mobile-img">
                        <img src="assets/img/mobile-img.png" />
                      </div>
                      <div className="card-data">
                        <div className="card pt-3 pb-4 px-4 pt-0 p-md-5">
                          <div className="apk-text">
                            <p>
                              Banking SMS APK Version :{" "}
                              {apkData && apkData.version
                                ? apkData.version
                                : ""}
                            </p>
                            <p>
                              Release Date and Time :
                              {" " +
                                formatDate(
                                  apkData && apkData.createdAt
                                    ? apkData.createdAt
                                    : ""
                                )}
                            </p>
                          </div>
                          <div className="apk-download-btn text-center mt-3">
                            <button
                              type="button"
                              className="btn btn-light"
                              onClick={downloadAPK}
                            >
                              Download
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <footer className="sticky-footer bg-white">
            <div className="container my-auto">
              <div className="copyright text-center my-auto">
                <span>Copyright &copy; Blue Iris Software Trading LLC</span>
              </div>
            </div>
          </footer>
        </div>
      </div>
    </>
  );
};

export default APK;
