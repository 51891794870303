import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const schema = z.object({
  email: z.string().min(1, { message: "Enter valid Email" }),
  password: z
    .string()
    .min(8, { message: "Password must contain at least 8 character(s)" }),
});

const Login = () => {
  const navigate = useNavigate();
  const [validationMessage, setValidationMessage] = useState("");

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(schema),
  });

  const userLogin = (event) => {
    axios
      .post(process.env.REACT_APP_BASE_URL + "signin", {
        email: event.email,
        password: event.password,
      })
      .then((response) => {
        localStorage.setItem("user", JSON.stringify(response.data.result));
        navigate("/");
      })
      .catch((error) => {
        setValidationMessage("Invalid credentials. Please try again.");
        // toast.error("Invalid credentials. Please try again.");
        // console.error("There was an error with the request:", error);
      });
  };

  return (
    <div className="container">
      {/* <ToastContainer /> */}
      {/* <!-- Outer Row --> */}
      <div className="row justify-content-center">
        <div className="col-xl-10 col-lg-12 col-md-9">
          <div className="card o-hidden border-0 shadow-lg my-5">
            <div className="card-body p-0">
              {/* <!-- Nested Row within Card Body --> */}
              <div className="row">
                <div className="col-lg-6 d-none d-lg-block bg-login-image p-5 text-center">
                  <img
                    style={{ width: "200px", height: "200px" }}
                    src={`${process.env.PUBLIC_URL}/assets/img/login_img.png`}
                    alt="Login"
                  />
                </div>
                <div className="col-lg-6">
                  <div className="p-5">
                    <div className="text-center">
                      <h1 className="h4 text-gray-900 mb-4">Welcome Back!</h1>
                    </div>

                    <form className="user">
                      <div className="form-group">
                        <input
                          type="email"
                          className="form-control form-control-user"
                          id="exampleInputEmail"
                          aria-describedby="emailHelp"
                          placeholder="Enter Email Address..."
                          name="email"
                          {...register("email")}
                        />
                        {errors.email?.message && (
                          <p className="validation-error">
                            {errors.email?.message}
                          </p>
                        )}
                      </div>
                      <div className="form-group">
                        <input
                          type="password"
                          className="form-control form-control-user"
                          id="exampleInputPassword"
                          placeholder="Password"
                          name="password"
                          {...register("password")}
                        />
                        {errors.password?.message && (
                          <p className="validation-error">
                            {errors.password?.message}
                          </p>
                        )}

                        {!errors.email?.message &&
                          !errors.password?.message &&
                          validationMessage && (
                            <p className="validation-error">
                              {validationMessage}
                            </p>
                          )}
                      </div>
                      <button
                        type="submit"
                        className="btn btn-primary btn-user btn-block"
                        onClick={handleSubmit((e) => {
                          userLogin(e);
                        })}
                      >
                        Login
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
