import React, { useEffect, useState } from "react";
import Sidebar from "./Sidebar";
import Navbar from "./Navbar";
import DeviceTable from "./DeviceTableColumns";
import axios from "axios";
import Papa from "papaparse";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";

const Devices = () => {
  const navigate = useNavigate();
  const [searchData, setSearchData] = useState("");
  const [inputData, setInputData] = useState("");
  const [filterDates, setFilterDates] = useState("");
  const [filterAction, setFilterAction] = useState(false);
  const [loading, setLoading] = useState(false);
  // const userDetails = JSON.parse(localStorage.getItem("user"));
  const [filterColumn, setFilterColumn] = useState("");
  const [column, setColumn] = useState("");
  const [dates, setDates] = useState({
    startDate: "",
    endDate: "",
  });

  const handleDates = (e) => {
    const { name, value } = e.target;
    setDates({
      ...dates,
      [name]: value,
    });
  };

  const toggleFilter = () => {
    if (filterAction) {
      setDates({
        startDate: "",
        endDate: "",
      });
      setSearchData("");
      setInputData("");
      setFilterDates("");
      setFilterColumn("");
      setColumn("");
    } else {
      setFilterDates(dates);
      setInputData(searchData);
      setFilterColumn(column);
    }
    setFilterAction(!filterAction);
  };

  const handleDevicesCSV = () => {
    setLoading(true);

    const userDetails = JSON.parse(localStorage.getItem("user"));
    if (!userDetails || !userDetails.token) {
      setLoading(false);
      navigate("/logout");
    }

    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}handle-devices-csv?filter=${
          inputData || ""
        }&startdate=${filterDates.startDate || ""}&enddate=${
          filterDates.endDate || ""
        }&column=${filterColumn}`,
        {
          headers: {
            Authorization: `Bearer ${userDetails.token}`,
          },
        }
      )
      .then((response) => {
        if (response.data.result.length > 0) {
          const csv = Papa.unparse(response.data.result);
          const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
          const link = document.createElement("a");
          const url = URL.createObjectURL(blob);
          link.setAttribute("href", url);
          link.setAttribute("download", "Device-List.csv");
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((error) => {
        console.error("Error fetching CSV data:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <ToastContainer />
      <div id="wrapper">
        <Sidebar />
        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            <Navbar />
            <div className="container-fluid">
              {/* <div className="text-center">
                {loading && (
                  <div
                    className="spinner-border text-primary"
                    role="status"
                  ></div>
                )}
              </div> */}
              <div className="card shadow mb-4">
                <div className="card-header py-3 d-flex justify-content-between">
                  <h6 className="m-0 font-weight-bold text-primary">
                    Device List
                  </h6>
                  <div className="d-flex align-items-center">
                    <div className="mx-0.5 d-flex flex-column">
                      <label htmlFor="start-date" style={{ color: "black" }}>
                        Start Date:
                      </label>
                      <input
                        type="date"
                        placeholder="Enter for search..."
                        name="startDate"
                        value={dates.startDate}
                        onChange={handleDates}
                        onKeyDown={(e) => e.preventDefault()}
                      />
                    </div>
                    <div className="mx-2 d-flex flex-column">
                      <label htmlFor="end-date" style={{ color: "black" }}>
                        End Date:
                      </label>
                      <input
                        type="date"
                        placeholder="Enter for search..."
                        name="endDate"
                        value={dates.endDate}
                        onChange={handleDates}
                        onKeyDown={(e) => e.preventDefault()}
                      />
                    </div>
                    <div className="mx-0.5 d-flex flex-column">
                      <label
                        htmlFor="column"
                        // className="mx-2"
                        style={{
                          // marginTop: "3px",
                          width: "auto",
                          color: "black",
                        }}
                      >
                        Column Head:
                      </label>
                      <select
                        value={column}
                        onChange={(e) => setColumn(e.target.value)}
                        style={{ height: "30px" }}
                      >
                        <option value={""}>Select One</option>
                        <option value={"name"}>Device</option>
                        <option value={"bank_name"}>Bank Name</option>
                        <option value={"account_title"}>Account Title</option>
                        <option value={"account_number"}>Account Number</option>
                        <option value={"department"}>Department</option>
                        <option value={"comment"}>Comment</option>
                      </select>
                    </div>
                    <div className="mx-2 d-flex flex-column">
                      <label htmlFor="end-date" style={{ color: "black" }}>
                        Column Value:
                      </label>
                      <input
                        type="text"
                        placeholder="Enter for search..."
                        name="filter"
                        value={searchData}
                        onChange={(e) => setSearchData(e.target.value)}
                      />
                    </div>

                    <div style={{ position: "relative", top: "15px" }}>
                      <button
                        className="btn btn-primary"
                        onClick={toggleFilter}
                        style={{ whiteSpace: "nowrap", width: "110px" }}
                        disabled={
                          !(dates.startDate && dates.endDate) &&
                          !(searchData && column)
                        }
                      >
                        {filterAction ? "Clear Filter" : "Apply Filter"}
                      </button>
                      <button
                        className="btn btn-primary mx-2"
                        onClick={handleDevicesCSV}
                      >
                        Download
                      </button>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <div className="table-responsive">
                    {loading && (
                      <div className="loader-container">
                        <div
                          className="spinner-border text-primary loader"
                          role="status"
                        >
                          <span className="sr-only">Loading...</span>
                        </div>
                      </div>
                    )}
                    <DeviceTable
                      containerId="containerA"
                      action={filterAction}
                      filter={inputData}
                      dates={filterDates}
                      column={filterColumn}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <footer className="sticky-footer bg-white">
            <div className="container my-auto">
              <div className="copyright text-center my-auto">
                <span>Copyright &copy; Blue Iris Software Trading LLC</span>
              </div>
            </div>
          </footer>
        </div>
      </div>
    </>
  );
};

export default Devices;
