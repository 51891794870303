import React from "react";
import moment from "moment-timezone";

const formatDate = (dateString) => {
  if (!dateString) return "N/A";

  return moment.utc(dateString).format("YYYY-MM-DD hh:mm:ss A");
};

const copyToClipboard = (text) => {
  // navigator.clipboard.writeText(text).then(
  //   () => {
  //     alert("Full description copied to clipboard!"); // Optional: Confirmation alert
  //   },
  //   (err) => {
  //     console.error("Failed to copy text: ", err);
  //   }
  // );

  const textArea = document.createElement("textarea");
  textArea.value = text;
  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();
  try {
    document.execCommand("copy");
    alert("Full description copied to clipboard!"); // Optional: Confirmation alert
  } catch (err) {
    console.error("Unable to copy to clipboard", err);
  }
  document.body.removeChild(textArea);
};

const tableColumns = (currentPage, pageSize) => [
  {
    name: "Sr No.",
    selector: (row, index) => index + 1 + (currentPage - 1) * pageSize,
  },
  // {
  //   name: "Description",
  //   selector: (row) => (
  //     <p className="description" title={row.description}>
  //       {row.description}
  //     </p>
  //   ),
  //   width: "30%",
  // },
  {
    name: "Description",
    selector: (row) => (
      <p
        className="description"
        title={row.description}
        style={{ cursor: "pointer" }}
        onDoubleClick={() => copyToClipboard(row.description)} // Copy full text on click
      >
        {row.description.length > 10
          ? row.description.slice(0, 10) + "..."
          : row.description}
      </p>
    ),
    // width: "30%", // Uncomment and adjust the width if needed
  },
  {
    name: "Device Name",
    selector: (row) => (
      <p
        style={{
          whiteSpace: "normal",
          overflow: "visible",
          textOverflow: "clip",
        }}
      >
        {row.device && row.device.name
          ? row.device.name
          : row.device_name
          ? row.device_name
          : ""}
      </p>
    ),
  },
  {
    name: "Bank Name",
    selector: (row) => (
      <p
        style={{
          whiteSpace: "normal",
          overflow: "visible",
          textOverflow: "clip",
        }}
      >
        {row.bank_name ? row.bank_name : ""}
      </p>
    ),
  },
  {
    name: "Account Title",
    selector: (row) => (
      <p
        style={{
          whiteSpace: "normal",
          overflow: "visible",
          textOverflow: "clip",
        }}
      >
        {row.account_title ? row.account_title : ""}
      </p>
    ),
  },

  {
    name: "Account Number",
    selector: (row) => (row.account_number ? row.account_number : ""),
  },
  {
    name: "Department",
    selector: (row) => (
      <p
        style={{
          whiteSpace: "normal",
          overflow: "visible",
          textOverflow: "clip",
        }}
      >
        {row.department && row.department.name
          ? row.department.name
          : row.department_name
          ? row.department_name
          : ""}
      </p>
    ),
  },
  {
    name: "Amount",
    selector: (row) => (
      <p
        style={{
          whiteSpace: "normal",
          overflow: "visible",
          textOverflow: "clip",
        }}
      >
        {row.amount ? row.amount : ""}
      </p>
    ),
  },
  {
    name: "Account Balance",
    selector: (row) => (
      <p className="kzZBXj">{row.available ? row.available : ""}</p>
    ),
  },
  {
    name: "Available Balance",
    selector: (row) =>
      row.available_balance ? row.available_balance : row.available,
  },

  {
    name: "Channel",
    selector: (row) => (row.channel ? row.channel : ""),
  },

  {
    name: "Type of Txn",
    selector: (row) => (row.txn_type ? row.txn_type : ""),
  },
  {
    name: "Dr/Cr",
    selector: (row) => (row.type ? row.type : ""),
  },
  {
    name: "Ref No./RRN/UTR",
    selector: (row) => (
      <p
        style={{
          whiteSpace: "normal",
          overflow: "visible",
          textOverflow: "clip",
        }}
      >
        {row.refNo ? row.refNo : ""}
      </p>
    ),
  },
  {
    name: "Transaction Date and Time from Message",
    selector: (row) => (
      <p
        style={{
          whiteSpace: "normal",
          overflow: "visible",
          textOverflow: "clip",
        }}
      >
        {row.tr_created_date ? row.tr_created_date : "00:00:00"}
      </p>
    ),
  },

  {
    name: "CreatedDate",
    selector: (row) => {
      if (!row.createdAt) return "";

      return formatDate(row.createdAt);
    },
  },
  {
    name: "Transaction Type",
    selector: (row) => (
      <label
        className="mb-0 bg-info text-center"
        style={{
          width: "60px",
          color: "white",
          padding: "5px",
          borderRadius: "5px",
          fontWeight: "bold",
        }}
      >
        {row.transaction_type}
      </label>
    ),
  },
];

export default tableColumns;
