import React, { useState } from "react";
import Sidebar from "./Sidebar";
import Navbar from "./Navbar";
import axios from "axios";
import Papa from "papaparse";
import { useNavigate } from "react-router-dom";

const TransactionCSV = () => {
  const userDetails = JSON.parse(localStorage.getItem("user"));
  const navigate = useNavigate();
  if (!userDetails || !userDetails.token) {
    navigate("/logout");
  }

  const [loading, setLoading] = useState(false);
  const [dates, setDates] = useState({
    startdate: "",
    enddate: "",
  });

  const handleDates = (e) => {
    const { name, value } = e.target;
    setDates({
      ...dates,
      [name]: value,
    });
  };

  const downloadCSV = () => {
    setLoading(true);

    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}handle-csv`,
        {
          startdate: dates.startdate,
          enddate: dates.enddate,
        },
        {
          headers: {
            Authorization: `Bearer ${userDetails.token}`,
          },
        }
      )
      .then((response) => {
        generateAndDownloadCSV(response.data.result);
      })
      .catch((error) => {
        console.error("Error fetching CSV data:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const generateAndDownloadCSV = (csvData) => {
    const csv = Papa.unparse(csvData);
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", "transactions.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <>
      <div id="wrapper">
        <Sidebar />
        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            <Navbar />
            <div className="container-fluid">
              <div className="d-sm-flex align-items-center justify-content-between mb-4">
                <h1 className="h3 mb-0 text-gray-800">
                  Download Transaction CSV
                </h1>
              </div>

              <div className="text-center">
                {loading && (
                  <div
                    className="spinner-border text-primary"
                    role="status"
                  ></div>
                )}
              </div>

              <div className="row">
                <div className="col-md-12 d-flex justify-content-center">
                  <form className="col-md-6">
                    <div className="mb-3 mt-3">
                      <label htmlFor="startdate">Start Date:</label>
                      <input
                        type="date"
                        className="mx-2 form-control"
                        name="startdate"
                        placeholder="Select start date"
                        onChange={handleDates}
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="enddate">End Date:</label>
                      <input
                        type="date"
                        className="mx-2 form-control"
                        name="enddate"
                        placeholder="Select end date"
                        onChange={handleDates}
                      />
                    </div>
                    <button
                      type="button"
                      onClick={downloadCSV}
                      className="btn btn-primary mx-3"
                    >
                      Download CSV
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>

          <footer className="sticky-footer bg-white">
            <div className="container my-auto">
              <div className="copyright text-center my-auto">
                <span>Copyright &copy; Banking SMS 2024</span>
              </div>
            </div>
          </footer>
        </div>
      </div>
    </>
  );
};

export default TransactionCSV;
