import React from "react";
import { Link, useLocation } from "react-router-dom";

const Sidebar = () => {
  const location = useLocation();
  return (
    <ul
      className="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion"
      id="accordionSidebar"
    >
      {/* <!-- Sidebar - Brand --> */}
      <Link
        className="sidebar-brand d-flex align-items-center justify-content-center"
        to="/"
      >
        <div className="sidebar-brand-icon rotate-n-15">
          <svg
            width="35px"
            height="35px"
            viewBox="0 0 1015 1113"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M32.7363 589.236C32.7363 686.353 61.5347 781.288 115.489 862.038C169.444 942.787 246.133 1005.72 335.857 1042.89C425.58 1080.05 524.31 1089.78 619.56 1070.83C714.811 1051.88 802.304 1005.12 870.976 936.447C939.647 867.775 986.413 780.282 1005.36 685.031C1024.31 589.781 1014.58 491.051 977.417 401.328C940.252 311.604 877.316 234.915 796.567 180.96C715.817 127.005 620.882 98.207 523.765 98.207C459.282 98.207 395.431 110.908 335.857 135.584C276.282 160.261 222.152 196.43 176.555 242.026C130.959 287.622 94.7902 341.753 70.1137 401.328C45.4372 460.902 32.7363 524.753 32.7363 589.236ZM189.404 781.753C189.437 776.554 191.521 771.579 195.202 767.909C198.884 764.238 203.866 762.17 209.065 762.154H232.137V738.338C232.17 733.15 234.245 728.183 237.914 724.515C241.582 720.846 246.548 718.771 251.736 718.739H285.848V442.306H232.137C226.47 442.335 220.966 440.404 216.56 436.839C212.154 433.273 209.116 428.294 207.963 422.745C206.809 417.195 207.61 411.418 210.23 406.392C212.85 401.366 217.128 397.401 222.338 395.169L513.966 268.768C517.049 267.431 520.374 266.742 523.734 266.742C527.095 266.742 530.42 267.431 533.503 268.768L825.193 395.169C830.403 397.401 834.681 401.366 837.301 406.392C839.921 411.418 840.722 417.195 839.568 422.745C838.414 428.294 835.377 433.273 830.971 436.839C826.565 440.404 821.061 442.335 815.394 442.306H761.744V718.739H795.795C800.982 718.771 805.949 720.846 809.617 724.515C813.286 728.183 815.361 733.15 815.394 738.338V762.154H838.466C843.664 762.17 848.646 764.238 852.328 767.909C856.01 771.579 858.094 776.554 858.127 781.753V805.508H189.404V781.753ZM712.623 488.202H619.466V718.739H712.623V488.202ZM350.476 393.184H697.055L523.765 318.138L350.476 393.184ZM570.344 488.202H477.187V718.739H570.282L570.344 488.202ZM428.065 488.202H334.908V718.739H428.065V488.202Z"
              fill="white"
            />
          </svg>
        </div>
        <div className="sidebar-brand-text mx-3">Banking SMS</div>
      </Link>

      {/* <!-- Divider --> */}
      <hr className="sidebar-divider my-0" />

      {/* <!-- Nav Item - Dashboard --> */}
      <li className={`nav-item ${location.pathname === "/" ? "active" : ""}`}>
        <Link className="nav-link" to="/">
          <i className="fas fa-fw fa-tachometer-alt"></i>
          <span>Dashboard</span>
        </Link>
      </li>

      <hr className="sidebar-divider my-0" />
      {/* <!-- Nav Item - Pages Collapse Menu --> */}
      <li
        className={`nav-item ${
          location.pathname === "/devices" ? "active" : ""
        }`}
      >
        <Link
          className="nav-link d-flex
          "
          to="/devices"
          data-target="#collapseThree"
          aria-expanded="true"
          aria-controls="collapseThree"
        >
          {/* <i className="fas fa-fw fa-cog"></i> */}
          <i
            className="fas fa-mobile-alt"
            style={{ fontSize: "20px", width: "15px" }}
          ></i>
          <span>Device List</span>
        </Link>
      </li>
      <li
        className={`nav-item ${
          location.pathname === "/transactions" ? "active" : ""
        }`}
      >
        <Link
          className="nav-link d-flex"
          to="/transactions"
          data-target="#collapseTwo"
          aria-expanded="true"
          aria-controls="collapseTwo"
        >
          {/* <i className="fas fa-fw fa-cog"></i> */}
          <i
            className="fas fa-exchange-alt"
            style={{ fontSize: "21px", width: "23px" }}
          ></i>
          <span>Transactions</span>
        </Link>
      </li>
      <li
        className={`nav-item ${
          location.pathname === "/excel-upload" ? "active" : ""
        }`}
      >
        <Link
          className="nav-link d-flex"
          to="/excel-upload"
          data-target="#collapseTwo"
          aria-expanded="true"
          aria-controls="collapseTwo"
        >
          <i
            className="fa fa-upload"
            aria-hidden="true"
            style={{ fontSize: "21px", width: "23px" }}
          ></i>
          <span>Bank Statement Upload</span>
        </Link>
      </li>
      <li
        className={`nav-item ${
          location.pathname === "/apk-download" ? "active" : ""
        }`}
      >
        <Link
          className="nav-link d-flex"
          to="/apk-download"
          data-target="#collapseTwo"
          aria-expanded="true"
          aria-controls="collapseTwo"
        >
          <i
            className="fa fa-download"
            aria-hidden="true"
            style={{ fontSize: "21px", width: "23px" }}
          ></i>
          <span>Latest APK Download</span>
        </Link>
      </li>
    </ul>
  );
};

export default Sidebar;
