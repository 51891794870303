import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import axios from "axios";
import ExcelDataTable from "./ExcelDataColumns";
import { useNavigate } from "react-router-dom";

const ExcelData = ({ refresh }) => {
  const [ExcelData, setExcelData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const userDetails = JSON.parse(localStorage.getItem("user"));
  const navigate = useNavigate();

  if (!userDetails || !userDetails.token) {
    navigate("/logout");
  }

  const fetchData = (page, limit) => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}excel-data-fetch?page=${page}&limit=${limit}`,
        {
          headers: {
            Authorization: `Bearer ${userDetails.token}`,
          },
        }
      )
      .then((response) => {
        if (response.data.result.length > 0) {
          setExcelData(response.data.result);
          setCurrentPage(response.data.currentPage);
          setTotalRecords(response.data.totalRecords);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  useEffect(() => {
    fetchData(currentPage, rowsPerPage);
  }, [currentPage, rowsPerPage]);

  // Trigger refresh when the "refresh" prop changes
  useEffect(() => {
    if (refresh) {
      fetchData(currentPage, rowsPerPage);
    }
  }, [refresh]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleRowsPerPageChange = (newPerPage) => {
    setRowsPerPage(newPerPage);
    setCurrentPage(1);
  };

  const columns = ExcelDataTable({ currentPage, rowsPerPage });

  return (
    <DataTable
      data={ExcelData}
      columns={columns}
      pagination
      paginationServer
      paginationTotalRows={totalRecords}
      onChangePage={handlePageChange}
      onChangeRowsPerPage={handleRowsPerPageChange}
      currentPage={currentPage}
      rowsPerPage={rowsPerPage}
    />
  );
};

export default ExcelData;
