import ExcelErororModal from "../components/ExcelErrorModal";
import moment from "moment-timezone";

const formatDate = (dateString) => {
  if (!dateString) return "N/A";

  return moment.utc(dateString).format("YYYY-MM-DD hh:mm:ss A");
};

const ExcelDataTable = ({ currentPage, rowsPerPage }) => {
  const columns = [
    {
      name: "Sr No.",
      selector: (row, index) => (currentPage - 1) * rowsPerPage + index + 1,
      width: "100px",
    },
    {
      name: "Date",
      selector: (row) => {
        if (!row.createdAt) return "";

        return formatDate(row.createdAt);
      },
      width: "300px",
    },
    {
      name: "File Name",
      selector: (row) => row.name,
      width: "300px",
    },
    {
      name: "File",
      cell: (row) => (
        <span
          style={{
            color:
              row.file === "Processed"
                ? "green"
                : row.file === "Partially Processed"
                ? "blue"
                : "red",
            fontWeight: "bold",
          }}
        >
          {row.file}
        </span>
      ),
    },
    {
      name: "Records",
      selector: (row) => row.records,
    },
    {
      name: "Success",
      selector: (row) => row.success,
    },
    {
      name: "Failed",
      selector: (row) => row.failed,
    },
    {
      name: "Error Details",
      selector: (row) => <ExcelErororModal id={row._id} />,
      width: "200px",
    },
  ];

  return columns;
};

export default ExcelDataTable;
