import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const ExcelErrorModal = ({ id }) => {
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  // const userDetails = JSON.parse(localStorage.getItem("user"));
  // if (!userDetails || !userDetails.token) {
  //   navigate("/logout");
  // }

  const fetchData = (page, limit) => {
    const userDetails = JSON.parse(localStorage.getItem("user"));
    if (!userDetails || !userDetails.token) {
      navigate("/logout");
    }

    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}excel-data-fetch-by-id?page=${page}&limit=${limit}`,
        { id: id },
        {
          headers: {
            Authorization: `Bearer ${userDetails.token}`,
          },
        }
      )
      .then((response) => {
        const processedData = [];
        if (
          response.data &&
          response.data.result &&
          Array.isArray(response.data.result.errorDetails)
        ) {
          response.data.result.errorDetails.forEach((error, index) => {
            processedData.push({
              serialNo: index + 1, // Serial number starts from 1
              serial: error.serial,
              errorType: error.error || "",
            });
          });
          setData(processedData);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  useEffect(() => {
    fetchData();
  }, [id]);

  const columns = [
    {
      name: "Sr No.",
      selector: (row) => row.serialNo,
    },
    {
      name: "Excel Serial No.",
      selector: (row) => (row.serial ? row.serial : "Not Found"),
    },
    {
      name: "Error",
      selector: (row) => row.errorType,
    },
  ];

  return (
    <>
      <div className="container">
        <span
          data-toggle="modal"
          data-target={`#errorModal${id}`}
          style={{
            color: "blue",
            fontWeight: "bold",
            cursor: "pointer",
            marginLeft: "-23px",
          }}
        >
          View Report
          <i
            className="fa fa-chevron-right"
            style={{ marginLeft: "10px" }}
            aria-hidden="true"
          ></i>
        </span>

        <div className="modal fade" id={`errorModal${id}`}>
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Error Report</h4>
                <button type="button" className="close" data-dismiss="modal">
                  &times;
                </button>
              </div>

              <div className="modal-body">
                <DataTable data={data} columns={columns} pagination />
              </div>

              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-danger"
                  data-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ExcelErrorModal;
