import React, { useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const Logout = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const userDetails = JSON.parse(localStorage.getItem("user"));
    if (!userDetails || !userDetails.token) {
      navigate("/logout");
    }

    if (userDetails && userDetails != null) {
      axios
        .get(process.env.REACT_APP_BASE_URL + "logout", {
          headers: {
            Authorization: `Bearer ${userDetails.token}`,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            localStorage.clear();
          }

          navigate("/login");
        })
        .catch((error) => {
          localStorage.clear();
          navigate("/login");
        });
    } else {
      localStorage.clear();
      navigate("/login");
    }
  }, [navigate]);
};

export default Logout;
